import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import {RenderFullBrandPreview} from '../Coach/HomeCoach';
import OneOnOneSupport from '../Support/OneOnOneSupport'; 
import TuneIcon from '@material-ui/icons/Tune';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import Card from '@material-ui/core/Card';
import Badge from '@material-ui/core/Badge';
import BadgeCard from '../Modules/BadgeCard';

import Drawer from '@material-ui/core/Drawer';
import imageSettingGoal from "../../images/settinggoal.png";
import imageSettingQuality from "../../images/settingquality.png";
import logoHotline from "../../images/Badge_hotline.png";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Fab from '@material-ui/core/Fab';
import { LinkBookMeeting } from '../../constants/routes';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    drawerOverview: {
        marginTop: 100,
        maxWidth: 600,
        border: 0,
    },
    fabRow: {
        position: 'fixed',
        //bottom: theme.spacing(2),
        right: theme.spacing(2),
        //marginLeft: 'auto',
        //marginRight: 'auto',
        marginTop: '20vh',
        //maxWidth: 460,
        //width: '80%',
        zIndex: 1000,
        transform: 'translate3d(0,0,0)',
    },
});




class DrawerGoalOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDrawer: false,
        };
    }

    render() {
        const { theme, classes, defaultSet } = this.props;
        const { leadershipCategories, leadershipQualities, user } = this.props;
        //console.log('DBO props', this.props)
        if(this.state.openDrawer || this.props.open) return(
            <Drawer
                open={this.state.openDrawer || this.props.open}
                style={theme.DrawerStatusOverview}
                onClose={() => {this.setState({ openDrawer: false})}}
                variant="temporary"
                anchor="right"
            >
                {this.state.openDrawer 
                &&
                    <div style={{maxWidth: theme.maxWidthDialog, marginLeft: 20, marginBottom: 20}}>
                        {this.state.showTopic === 'brand'
                        &&
                            <>
                                <h2>{user?.roles?.includes('COACHADMIN') ? "Brand Preview" : "My AI Coach"}</h2>
                                <div style={{margin: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:0, padding: 20, backgroundColor: theme.palette.backPaper}}>
                                    {user?.roles?.includes('COACHADMIN')
                                        ?
                                            defaultSet?.programMinPrograms?.length > 0 && user.adminPrograms?.some(programItem => defaultSet.programMinPrograms.includes(programItem))
                                            ?   RenderFullBrandPreview(this, defaultSet)
                                            :   <>
                                                    {RenderFullBrandPreview(this, {})}
                                                    {defaultSet?.programTitle?.length > 2
                                                    &&
                                                        <>
                                                            <br/><br/>
                                                            {RenderFullBrandPreview(this, defaultSet)}
                                                        </>
                                                    }
                                                </>
                                        :   RenderFullBrandPreview(this, defaultSet)
                                    }
                                </div>
                            </>
                        }
                        {this.state.showTopic === 'support'
                        &&
                            <>
                                <h2>
                                    {user?.roles?.includes("COACHADMIN") 
                                        ?   "Technical Support"
                                        :   "Support"
                                    }
                                </h2>
                                <p style={theme.textSupport}>
                                    {user?.roles?.includes("COACHADMIN") 
                                    ?   "Send a message to the Rocky.ai Team"
                                    :   this.props.defaultSet?.programCode?.length > 2
                                        ?   "Send a message to: " + this.props.defaultSet?.programCode
                                        :   "Send a message"
                                    }
                                </p>
                                <div style={{paddingRight: 20}}>
                                    <OneOnOneSupport 
                                        userId={this.props.user?.uid} 
                                        user={this.props.user} 
                                        topic={user?.roles?.includes("COACHADMIN") ? "Technical Support" : "Support" }
                                        groupId={(!(user?.roles?.includes("COACHADMIN")) && this.props.defaultSet?.programCode?.length > 2) ? this.props.defaultSet.programCode : "default"}
                                        isCoach={false}
                                    />
                                    {user?.roles?.includes("COACHADMIN")
                                    ?
                                        <BadgeCard
                                            backgroundColor ={"white"}
                                            image={logoHotline} 
                                            avatarImage = {undefined}
                                            title = {'Book 1-on-1'}
                                            subtitle = {'Service Call'}
                                            strongSubtitle = {true}
                                            onClick={ () => window.open(LinkBookMeeting(this), "_blank") }
                                            button={<>Book&nbsp;<HeadsetMicIcon /></>}
                                        /> 
                                    :   (this.props.defaultSet?.programBookingURL?.includes("http"))
                                        ?   
                                            <BadgeCard
                                                backgroundColor ={"white"}
                                                image={logoHotline} 
                                                avatarImage = {undefined}
                                                title = {'Book 1-on-1'}
                                                subtitle = {'Service Call'}
                                                strongSubtitle = {true}
                                                onClick={ () => window.open(LinkBookMeeting(this), "_blank") }
                                                button={<>Book&nbsp;<HeadsetMicIcon /></>}
                                            />
                                        :   this.props.defaultSet?.programSupportURL?.includes("http")
                                            ?
                                                <BadgeCard
                                                    backgroundColor ={"white"}
                                                    image={logoHotline} 
                                                    avatarImage = {undefined}
                                                    title = {'Contact Us'}
                                                    subtitle = {'Our service information'}
                                                    strongSubtitle = {true}
                                                    onClick={ () => window.open(LinkBookMeeting(this), "_blank") }
                                                    button={<>Contact&nbsp;<HeadsetMicIcon /></>}
                                                /> 
                                            :
                                                ""
                                    }
                                </div>
                            </>
                        }
                        <div style={{textAlign: "right", marginRight: 20}}>
                            <Button onClick={() => {this.setState({ openDrawer: false})}}>
                                Close
                            </Button>
                        </div>
                    </div>
                }
            </Drawer>
        )
        else if(this.props.open === undefined) return(
            <Card className={classes.fabRow}>
                <List>
                    {true
                    &&
                        <ListItem button key={'focus'} onClick={() => {this.setState({openDrawer: true, showTopic: 'support'})}}>
                            <Badge overlap='circular'badgeContent={this.props.user?.newMessages!== undefined && this.props.user?.newMessages.filter(q => q.type === "SUPPORT").length ? this.props.user?.newMessages.filter(q => q.type === "SUPPORT").length : 0} 
                                color="error">
                                <Fab color="primary" size='small' > 
                                    <HeadsetMicIcon />
                                </Fab>
                            </Badge>
                        </ListItem>
                    }
                    {defaultSet?.programLogo?.includes("http") && 
                        <ListItem button key={'goals'} onClick={() => {this.setState({openDrawer: true, showTopic: 'brand'})}}>
                            <Fab color="primary" size='small' >
                                {defaultSet?.programLogo?.includes("http")
                                    ?
                                        <img
                                            src={defaultSet.programLogo}
                                            style={{...theme.logo, width: '100%', maxWidth: 600}} 
                                            alt="AI Coaching Chatbot" 
                                        />
                                    :
                                        <img src={imageSettingGoal} alt='My Vision' style={theme.cardSettings.iconDesign} />
                                } 
                            </Fab>
                        </ListItem>
                    }
                    {this.props.openSettings !== undefined && 
                        <ListItem button key={'settings'} onClick={() => {this.props.openSettings()}}>
                            <Fab color="primary" size='small' >
                                <TuneIcon/>
                            </Fab>
                        </ListItem>
                    }
                    {this.props.openTutorial !== undefined && 
                        <ListItem button key={'help'} onClick={() => {this.props.openTutorial()}}>
                            <Fab color="primary" size='small' >
                                <HelpOutlineIcon/>
                            </Fab>
                        </ListItem>
                    }
                    {false && defaultSet?.programTitle?.length > 0
                    &&
                        <ListItem button key={'focus'}>
                            <Fab color="primary" size='small' > 
                                <img src={imageSettingQuality} alt='Coaching Module' style={theme.cardSettings.iconDesign} />
                            </Fab>
                        </ListItem>
                    }
                </List>
            </Card>
        )
    }
}

export default compose(
    withStyles(styles, { withTheme: true }),
)(DrawerGoalOverview);
